<template>
  <div>
    <validation-observer ref="observer">
      <form @submit.prevent="save">
        <div class="task-container pa-9 pa-md-6 pa-lg-9 mx-10">
          <v-container>
            <v-row align="center">
              <v-col cols="12" class="mb-2">
                <h3 class="page-title font-weight-bold">
                  パートナー会社の登録
                </h3>
                <span>新しい協力会社情報の登録を行います。</span>
              </v-col>

              <v-col offset-md="3" cols="3" class="mb-2">
                <img
                  :src="preview_file"
                  width="100%"
                  class="image_preview mb-2"
                />
                <div class="d-flex">
                  <v-btn text class="red--text" @click="removeFile()"
                    >ｰ 削除</v-btn
                  >
                  <v-spacer></v-spacer>
                  <v-btn text class="blue--text pointer" @click="openFile()"
                    >+ アップロード
                  </v-btn>
                </div>
                <validation-provider
                  v-slot="{ validate, errors }"
                  name="画像ファイル"
                  rules="image|ext:jpeg,jpg,png,gif|size:5120|min-dimensions:100,100"
                  ref="imageValidation"
                  :custom-messages="{
                    image: '画像ファイルを選択して下さい。',
                    ext: '画像ファイルはjpg, png, gifを選択して下さい。',
                    'min-dimensions':
                      '100px x 100px以上の画像を使用して下さい。',
                    size: '5Mb以下のファイルを使用して下さい。'
                  }"
                >
                  <input
                    type="file"
                    style="display: none"
                    ref="uploader"
                    accept="image/*"
                    @change="onFilePicked"
                  />
                  <p v-if="errors.length" class="error--text">
                    {{ errors[0] }}
                  </p>
                </validation-provider>
              </v-col>
              <v-col cols="12" id="dateTimePart">
                <div class="form-content">
                  <v-row align="center" v-if="data.type === 3">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      表示順
                    </v-col>
                    <v-col cols="7">
                      <validation-provider
                        v-slot="{ errors }"
                        name="display_order"
                        rules="between:1,99"
                      >
                        <v-text-field
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          v-model.number="data.display_order"
                          type="number"
                          class="form-text"
                          dense
                          hide-details="auto"
                          outlined
                          placeholder="1~99の数字を一つ入力してください。"
                          :disabled="disableForm"
                        >
                        </v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      パートナー会社のタイプ
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-select
                        v-model="data.type"
                        :items="types"
                        class="form-text"
                        dense
                        hide-details="auto"
                        outlined
                        placeholder="タイプを選択してください。"
                        @change="handleType()"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      会社名
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <v-text-field
                          v-model="data.name"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          class="form-text"
                          dense
                          hide-details="auto"
                          outlined
                          placeholder="会社名を入力してください。"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      郵便番号
                    </v-col>
                    <v-col cols="4" class="flex-grow-1">
                      <v-text-field
                        v-model="data.postcode"
                        @input="changePostal"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="0000000"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      都道府県
                    </v-col>
                    <v-col cols="4" class="flex-grow-1">
                      <v-select
                        v-model="data.prefecture_id"
                        :items="prefectures"
                        item-text="name"
                        item-value="id"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="都道府県を選択してください。"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      市区町村
                    </v-col>
                    <v-col cols="4" class="flex-grow-1">
                      <v-text-field
                        v-model="data.city"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="市区町村を入力してください。"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      番地・建物名・号室
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.address"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="番地・建物名・号室 を入力してください。"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      電話番号
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.phone"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="00000000000"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      メールアドレス
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.email"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="abc@example.com"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      メモ
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-textarea
                        v-model="data.memo"
                        class="form-text"
                        counter="500"
                        hide-details
                        outlined
                        placeholder="協力会社に関するメモを記入してください。"
                        rows="7"
                      ></v-textarea>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      担当者名
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.name_of_person_in_charge"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="担当者名を入力してください。"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      担当者名フリガナ
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.furigana_name_of_person_in_charge"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="担当者フリガナを入力してください。"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      担当者メールアドレス
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.email_address_of_person_in_charge"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="abc@example.com"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                    >
                      担当者電話番号
                    </v-col>
                    <v-col cols="7" class="flex-grow-1">
                      <v-text-field
                        v-model="data.mobile_number_of_person_in_charge"
                        class="form-text"
                        dense
                        hide-details
                        outlined
                        placeholder="00000000000"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="create-task-footer px-6">
          <v-row class="justify-end">
            <v-col cols="auto">
              <v-btn
                @click="$router.push('/management/partner')"
                text
                class="mr-8"
                rounded
                min-width="150"
                >キャンセル
              </v-btn>
            </v-col>

            <v-col cols="auto">
              <v-btn
                :loading="loading"
                @click="save"
                color="#4F55A7"
                class="white--text"
                min-width="150"
                >パートナー会社を登録する
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  name: 'CreatePartner',
  components: {},
  data() {
    return {
      preview: null,
      types: [
        { value: 0, text: 'マンション・ハウスメーカー' },
        { value: 1, text: '発注会社' },
        { value: 2, text: '元請会社' },
        { value: 3, text: '協力会社' }
      ],
      data: {
        city: null,
        prefecture: null,
        prefecture_id: null,
        profile_image: null
      },
      loading: false
    }
  },
  computed: {
    prefectures() {
      return this.$store.getters.allPrefectures
    },
    preview_file: {
      get() {
        return this.preview
          ? this.preview
          : process.env.VUE_APP_BASEURL + 'images/userblank-rectangle.png'
      },
      set(value) {
        this.preview = value
      }
    }
  },
  created() {
    this.$store.dispatch('PREFECTURE_GET_ALL')
  },
  methods: {
    changePostal() {
      const postal_code = require('japan-postal-code')
      this.resetPrefectureCity()

      postal_code.get(this.data.postcode, address => {
        let selected_prefecture = this.prefectures.filter(
          prefecture => address.prefecture === prefecture.name
        )[0]

        this.data.prefecture = selected_prefecture.name
        this.data.prefecture_id = selected_prefecture.id
        this.data.city = address.city
      })
    },
    resetPrefectureCity() {
      this.data.prefecture = null
      this.data.prefecture_id = null
      this.data.city = null
    },
    openFile() {
      this.$refs.uploader.click()
    },
    onFilePicked(e) {
      this.$refs.imageValidation.validate(e).then(success => {
        if (!success) {
          return
        }

        this.data.profile_image = e.target.files[0]
        this.preview_file = URL.createObjectURL(this.data.profile_image)
      })
    },
    removeFile() {
      this.data.profile_image = null
      this.preview = null
    },
     handleType(){
      if(this.data.type !== 3 && this.data.hasOwnProperty('display_order')){
        this.data.display_order = null
      }
    },
    save() {
      this.loading = true
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        let formData = new FormData()
        for (const [key, value] of Object.entries(this.data)) {
          formData.append(key, value ? value : value == 0 ? 0 : '')
        }
        this.$store
          .dispatch('PARTNER_CREATE', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(
            response => {
              if (response.data.status == 'success') {
                this.$router.push({
                  path: '/management/partner',
                  query: {
                    status: 'success',
                    message: response.data.data.message
                  }
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => {
            this.loading = false
          })
      })
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped></style>
